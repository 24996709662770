<template>
  <div>
    <div class="px-2">
      <div class="row d-flex align-items-center justify-content-center mb-2">
        <div class="col-12 col-md-4 mb-1 mb-md-0">
          <div class="form-group controls">
            <label for="discount_global_rate">Offer Rate (%) <span class="text-danger">*</span></label>
            <input v-model.number="currentSetting.value.amount.percentage_value"
                   @keypress="allowOnlyNumbers"
                   @input="clearError($event,'percentage_value')"
                   id="discount_global_rate" type="text"
                   :class="errors.percentage_value === ''? 'is-valid': 'is-invalid' "
                   class="form-control" placeholder="Offer rate" aria-describedby="discount_global_rate_block">
            <small id="discount_global_rate_block" class="form-text text-muted">
              Apply the selected service price deduction according to the rate.
            </small>
            <div class="form-text text-danger"><span>{{ errors.percentage_value }}</span></div><!-- Error message -->
          </div>
        </div>

        <div class="col-12 col-md-4 mb-1 mb-md-0">
          <div class="form-group controls">
            <label for="discount_global_amount" class="">Offer Amount (in cent) <span class="text-danger">*</span></label>
            <input v-model.number="currentSetting.value.amount.amount_value"
                   @keypress="allowOnlyNumbers"
                   @input="clearError($event,'amount_value')"
                   id="discount_global_amount" type="text"
                   :class="errors.amount_value === ''? 'is-valid': 'is-invalid' "
                   class="form-control" placeholder="Offer amount" aria-describedby="discount_global_amount_block">
            <small id="discount_global_amount_block" class="form-text text-muted">
              Apply the selected service price to the amount.
            </small>
            <div class="form-text text-danger"><span>{{ errors.amount_value }}</span></div><!-- Error message -->
          </div>
        </div>

        <div class="col-12 col-md-4 mb-1 mb-md-0">
          <div class="form-group">
            <div class="controls">
              <label>Offer type</label>
              <div style="margin-top: .5rem">
                <div
                    class="custom-control custom-switch custom-switch-danger custom-control-inline mb-1 d-flex align-items-center">
                  <div>
                    <input v-model="amountType" type="checkbox" class="custom-control-input"
                           id="amount_global_type" aria-describedby="amount_global_type_block">
                    <label class="custom-control-label mr-1" for="amount_global_type">
                      <span class="switch-icon-left"><i class="bx bx-dollar"></i></span>
                      <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                    </label>
                  </div>
                  <span class="font-medium-1 text-bold-600">{{
                      amountType === true ? "Amount" : "Percentage"
                    }}</span>
                </div>
                <small id="amount_global_type_block" class="form-text text-muted">
                  Switch to choose between amount and rate.
                </small>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="row d-flex align-items-center mb-1">

        <div class="col-12 col-md-5 col-lg-4">
          <div class="form-group controls">
            <label>Select date</label>
            <div class="d-flex align-items-center date-picker-wrapper">
              <div class="w-100" ref="picker">
                <DatePicker
                    v-model="selectedRanges"
                    :min-date="new Date()"
                    :attributes="dateAttributes"
                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}"
                    mode="range"
                    is-range
                    multiple
                ></DatePicker>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-5 col-lg-3 py-1">
          <label>Selected date <span class="text-danger">*</span></label>
          <div class="pb-2 py-1 rounded" :class="errors.date_range === ''? 'border': 'border-danger' ">
            <div v-for="(tag, index) in dateAttributes" :key="index">
              <div class="d-flex justify-content-between align-items-center m-1 pl-1 mb-1 rounded"
                   :style="{ backgroundColor: tag.highlight }">
                <p class="mb-0">
                  <span class="rounded text-white">{{ formatDate(tag.dates.start) }} - {{
                      formatDate(tag.dates.end)
                    }}</span>
                </p>
                <button class="cursor-pointer bg-transparent text-decoration-none border-0"
                        @click="deleteTag(index)">
                  <i class='bx bx-x text-white'></i>
                </button>
              </div>
            </div>
          </div>
          <div class="form-text text-danger">{{ errors.date_range }}</div> <!-- Error message -->
        </div>

        <div class="col-12 col-md-4 col-lg-4" v-if="showService">
          <div class="form-group">
            <div class="controls">
              <label>Select services <span class="text-danger">*</span></label>
              <VueMultiselect v-model="selectedService"
                              :options="serviceList"
                              :close-on-select="true"
                              :multiple="true"
                              :show-labels="false"
                              :allow-empty="true"
                              placeholder="Select services"
                              label="name"
                              :class="{'custom-border': errors.service_ids !==''}"
                              track-by="value"
              />
            </div>
            <div class="form-text text-danger">{{ errors.service_ids }}</div>
          </div>
        </div>

        <div class="col-12 col-md-2 col-lg-2 mb-1 mb-md-0">
          <div class="form-group">
            <div class="controls">
              <label>Status</label>
              <div style="margin-top: .5rem">
                <div
                    class="custom-control custom-switch custom-switch-success custom-control-inline mb-1 d-flex align-items-center">
                  <div>
                    <input v-model="checkBoxStatus" type="checkbox" class="custom-control-input"
                           id="current_status">
                    <label class="custom-control-label mr-1" for="current_status">
                      <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                      <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                    </label>
                  </div>
                  <span class="font-medium-1 text-bold-600">{{
                      checkBoxStatus === true ? "Active" : "Inactive"
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-flex align-items-center mb-1 justify-content-center">

        <div class="col-12 col-md-4">
          <div>
            <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler"
                    class="btn btn-dark btn-block px-lg-2"
                    type="submit">Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {DatePicker} from "v-calendar";
import VueMultiselect from "vue-multiselect";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";


export default {
  name: "ServiceGlobalFlatPrice",
  mixins: [ShowToastMessage, Loader, GlobalMixin],
  components: {
    DatePicker,
    VueMultiselect,
  },
  props: ['serviceGlobalFlatPrice', 'showService'],
  data() {
    return {
      selectedService: '',
      colors: ['gray', 'red', 'orange', 'green', 'teal', 'blue', 'indigo', 'purple', 'pink'],
      currentSetting: {
        id: '',
        type: '',
        key: '',
        value: {
          amount: {
            amount_value: 0,
            percentage_value: 0,
            type: 1
          },
          status: 0,
          date_range: [],
        }
      },
      checkBoxStatus: false,
      amountType: false,
      selectedRanges: [], // Holds multiple date ranges
      dateAttributes: [],
      errors: {
        amount_value: '',
        percentage_value: '',
        date_range: '',
        service_ids: ''
      }
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    },

  },
  watch: {
    serviceGlobalFlatPrice: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.currentSetting = {
          id: newVal?.id,
          type: newVal?.type,
          key: newVal?.key,
          value: {
            amount: {
              amount_value: newVal?.value?.amount?.amount_value ?? 0,
              percentage_value: newVal?.value?.amount?.percentage_value ?? 0,
              type: newVal?.value?.amount?.type ?? 1,
            },
            status: newVal?.value?.status ?? 0,
            date_range: newVal?.value?.date_range ?? [],
          },
        }

        newVal?.value?.date_range?.forEach((singleDate) => {
          this.generateDates(singleDate.start, singleDate.end)
        });
        this.amountType = newVal?.value?.amount?.type == 1;
        this.checkBoxStatus = newVal?.value?.status == 1;
      }
    },
    'currentSetting.value.status'(newValue) {
      this.checkBoxStatus = newValue == 1;
    },
    checkBoxStatus(newValue) {
      this.currentSetting.value.status = (newValue === true ? 1 : 0);
    },
    'currentSetting.value.amount.type'(newValue) {
      this.amountType = newValue == 1;
    },
    amountType(newValue) {
      this.currentSetting.value.amount.type = (newValue === true ? 1 : 0);
    },
    async selectedRanges(newValue) {
      // Only proceed if newValue has both start and end dates
      if (newValue.start && newValue.end) {

        this.generateDates(newValue.start, newValue.end);

        await this.$nextTick(() => {
          this.selectedRanges = []; // or any other logic
        });
      }
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
      getServices: "appService/getServices"
    }),
    // Clears the error message for a specific field
    clearError(event, field) {
      const value = event.target.value;  // Get the value from the input event
      if (value === '' || value === null) {
        this.errors[field] = "This field is required."; // Set error if the field is empty
      } else {
        this.errors[field] = ''; // Clear error if the field has a value
      }
    },
    validateForm() {
      let valid = true;
      // Reset errors
      this.errors = {
        amount_value: '',
        percentage_value: '',
        date_range: '',
      };

      // Validate offer amount
      if (!this.currentSetting.value.amount.amount_value) {
        this.errors.amount_value = "Offer amount is required.";
        valid = false;
      }

      // Validate percentage value
      if (!this.currentSetting.value.amount.percentage_value) {
        this.errors.percentage_value = "Offer rate is required.";
        valid = false;
      }

      // Validate date range
      if (this.dateAttributes.length === 0) {
        this.errors.date_range = "At least one date range is required.";
        valid = false;
      }

      return valid;
    },
    generateDates(start, end) {
      // Split the string into year, month, and day
      const sDate = this.convertToDate(start);
      const eDate = this.convertToDate(end);

      const sYear = sDate.getFullYear();
      const sMonth = sDate.getMonth();
      const sDay = sDate.getDate();

      const eYear = eDate.getFullYear();
      const eMonth = eDate.getMonth();
      const eDay = eDate.getDate();

      const currentKey = `${sYear}${sMonth}${sDay}${eYear}${eMonth}${eDay}`;

      // Check if the date range already exists in dateAttributes
      const index = this.dateAttributes.findIndex(({id}) => id === currentKey);
      if (index === -1) {
        // Add the new range to dateAttributes
        this.dateAttributes.push({
          highlight: this.getColor(),
          id: currentKey,
          dates: {
            start: sDate,
            end: eDate
          }
        });
      }
    },
    deleteTag(index) {
      this.dateAttributes.splice(index, 1);
      this.dateAttributes = [...this.dateAttributes];
    },
    formatDate(dateObj) {
      return `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
    },
    convertToDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day);
    },
    getColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex].toString();
    },

    async updateSettingsHandler() {
      if (this.validateForm()) {
        await this.updateSettings();
      } else {
        this.showToastMessage({
          message: "Please fix the errors before updating.",
          type: "error"
        });
      }
    },

    async updateSettings() {
      const dates = this.dateAttributes.map((dateObj) => {
        return {
          start: this.formatDate(dateObj.dates.start),
          end: this.formatDate(dateObj.dates.end)
        }
      });
      this.currentSetting.value.date_range = dates;


      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment service global flat price updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>


<style scoped>

:deep(.multiselect__tags) {
  max-height: -webkit-max-content !important;
  max-height: -moz-max-content !important;
  max-height: max-content !important;
  min-height: 40px !important;
}

:deep(.custom-border .multiselect__tags) {
  border-color: red !important; /* Apply the red border when the condition is met */
}
</style>