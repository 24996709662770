<template>
  <div class="d-flex m-auto w-100 mb-1">

    <div class="w-100 mr-1">
      <input v-model="currentSetting.value" type="text" class="form-control" id="appointmentReferencePrefix"
             placeholder="Appointment Reference Prefix">
    </div>

    <div>
      <button :disabled="isUpdateButtonEnable" @click="updateSettingsHandler" class="btn btn-primary px-md-2"
              type="submit">Update
      </button>
    </div>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";


export default {
  name: "AppointmentReferencePrefix",
  mixins: [ShowToastMessage, Loader],
  props: ['appointmentReferencePrefix'],
  data() {
    return {
      currentSetting: this.appointmentReferencePrefix,
    };
  },
  computed: {
    isUpdateButtonEnable() {
      return this.currentSetting.value === '' || this.currentSetting.value === null;
    }
  },
  methods: {
    ...mapActions({
      putSetting: "appSettings/putSetting",
    }),

    async updateSettingsHandler() {
      await this.updateSettings();
    },

    async updateSettings() {
      let dataObj = {
        id: this.currentSetting.id,
        data: {
          type: this.currentSetting.type,
          key: this.currentSetting.key,
          value: this.currentSetting.value,
        }
      };
      await this.loader(true);
      await this.putSetting(dataObj).then(async (response) => {
        await this.loader(false);

        if (response.status === 200) {
          const toastObj = {
            message: 'Appointment reference prefix updated.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
        }
        this.showToastMessage(response);
      });
    }
  },
}
</script>

<style scoped>

</style>